import React from "react"

import Layout from "@components/layout"
import OffSiteLink from '@components/off-site-link'
import AppleIcon from '@components/apple-icon'
import SEO from "@components/seo"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const WalkingCoursePage = () => (
  <Layout bodyClassName='course walking'>
    <SEO title="Walk Like a Champ" imageData={{src: "/walk-like-a-champ-meta.jpg" }} />
  
    <section className="breadcrumbNavigation">
      <div className="container container--lg">
        <a href="/">Home</a><a className="active" href="/courses/walking">Walk Like a Champ</a>
      </div>
    </section>

    <section className="stripe stripe--hero stripe--centered">
    </section>
  
    <section className="stripe stripe--title stripe--centered">
      <div className="container">
        <div className="copy">
          <h1 className="pageTitle">Walk Like a Champ</h1>
          <p className="lede">Learning to walk on a loose leash is hard, but this course breaks it down into four easy to practice sections.</p>

          <OffSiteLink id="courseTopCTA" className="btn btn--primary btn--block" href="https://harper.app.link/jXHHzWDfmbb" >
            <AppleIcon />
            <span>Get started today</span>
          </OffSiteLink>
          <span className="aside">A Harper membership is $8.33/month (billed annually)</span>
        </div>
      </div>
    </section>

    <section className="stripe">
      <div className="container">
        <div className="copy">
          <h3 className="chapterTitle">The search for the perfect walk</h3>
          <p className="prose">You&rsquo;ve probably felt it&mdash;that blissful moment when you forget your dog is with you and you&rsquo;re just enjoying the walk. Maybe it only lasted a few seconds, maybe it only happened because your dog slowed down so she could get a better look at the squirrel across the street, but that doesn&rsquo;t make the feeling any less real.</p>
          <p className="prose">That feeling is what we&rsquo;re after. That is great loose-leash walking.</p>
          <p className="prose">We all know that great walks with your dog don&rsquo;t just happen. Dogs don&rsquo;t show up knowing how to walk by our side on a leash.</p>
          <p className="prose">The truth is it takes work and a lot of patience to get on the same page. You have to earn it together.</p>
          <p className="prose">This course is going to give you the mindset, gear, techniques, support, and confidence to achieve great loose-leash walks with your dog.</p>
          
          <h3 className="chapterTitle">What your pup will learn</h3>
          <ul className="benefitList">
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">Give attention when they want something</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">At your side is a good place to be</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">How to move with you and stop with you</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">A cue to come close to your side</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">To ignore distractions</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">How to walk like a champ</span>
            </li>
          </ul>
  
          <h3 className="chapterTitle">What you&rsquo;ll learn</h3>
          <ul className="benefitList">
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">Elements of great loose-leash walking</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">How to always get your pup&rsquo;s attention</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">Ways to prevent leash pulling</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">Helpful cues for managing your walks</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">How to minimize reactions to distractions</span>
            </li>
            <li className="benefit">
              <span className="icon"></span>
              <span className="benefit__name">How to walk like a champ</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="stripe">
      <div className="container">
        <div className="copy">

        <h2 className="sectionTitle">Browse the lesson plan</h2>
        <h3 className="chapterTitle">Stage 1: Preparation</h3>
        <ul className="courseBlocks">
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Introduction to Walk Like a Champ</span>
                <span className="courseBlock__info">Article 1</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">What&rsquo;s this Walk Like a Champ course all about?</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">What is Great Loose Leash Walking?</span>
                <span className="courseBlock__info">Article 2</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Let&rsquo;s set some realistic expectations for your dog and yourself.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Why is Walking On a Leash So Hard?</span>
                <span className="courseBlock__info">Article 3</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Find out what you&rsquo;re up against.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">The Strategy for Training Great Loose-Leash Walks</span>
                <span className="courseBlock__info">Article 4</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">See how the pieces of our four-step process all fit together.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Collars vs Harnesses vs Head Halters</span>
                <span className="courseBlock__info">Article 5</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Learn about these popular restraints and figure out which one is right for you.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Supplies for Training to Walk Like a Champ</span>
                <span className="courseBlock__info">Article 6</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">What do you need and what&rsquo;s a nice-to-have?</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Walks are not Exercise</span>
                <span className="courseBlock__info">Article 7</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Blowing up one of dog parenting&rsquo;s biggest myths.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Get the Energy Out</span>
                <span className="courseBlock__info">Article 8</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">A spoonful of exercise helps the medicine go down.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Training Walks are not like Regular Walks</span>
                <span className="courseBlock__info">Article 9</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Why and how to separate the two.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Focus on the Section Not the Game</span>
                <span className="courseBlock__info">Article 10</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">It&rsquo;s about the forest, not the trees.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Glossary of Cues for Training Loose-Leash Walking</span>
                <span className="courseBlock__info">Article 11</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">A handy reference guide of loose-leash walking cues you&rsquo;ll use with your dog.</span>
            </div>
          </li>
        </ul>
      
        <h3 className="chapterTitle">Stage 2: Attention</h3>
        <ul className="courseBlocks">
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Introduction to Attention</span>
                <span className="courseBlock__info">Article 9</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">What&rsquo;s this Attention section all about?</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Don&rsquo;t Be Boring on Your Dog Walks</span>
                <span className="courseBlock__info">Article 10</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">How do you keep your pup&rsquo;s attention?</span>
            </div>
          </li>
  
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/raining-treats.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Raining Treats</span>
                <span className="courseBlock__info">Game 1</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">When you need to get your dog&rsquo;s attention or give her some exercise, make it rain.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Every Game is Not for Every Dog</span>
                <span className="courseBlock__info">Article 11</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Can a game that&rsquo;s meant to help actually hurt your progress?</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/face-me.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Face Me</span>
                <span className="courseBlock__info">Game 2</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Build your pup&rsquo;s instinct to check in with you.</span>
            </div>
          </li>
                
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Using Your Dog&rsquo;s Name to Get Attention</span>
                <span className="courseBlock__info">Article 12</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">How Harper thinks about the name cue.</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/distraction-ghosting.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Distraction Ghosting</span>
                <span className="courseBlock__info">Game 3</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">A fail-proof method for avoiding life&rsquo;s toughest distractions.</span>
            </div>
          </li>
                
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Using Leash Tension for Good Not Evil</span>
                <span className="courseBlock__info">Article 13</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Turn leash pulling into something useful.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/leash-pressure-cue.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Leash Pressure Cue</span>
                <span className="courseBlock__info">Game 4</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Teach your pup that a tight leash means &rdquo;come back towards me.&rdquo;</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Moving On from Attention</span>
                <span className="courseBlock__info">Article 14</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">How do you know you&rsquo;re done with Attention and ready for Position?</span>
            </div>
          </li>
        </ul>


        <h3 className="chapterTitle">Stage 3: Position</h3>
        <ul className="courseBlocks">
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Introduction to Position</span>
                <span className="courseBlock__info">Article 15</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">What&rsquo;s this Position section all about?</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Should I Walk My Dog On the Right Side or the Left Side?</span>
                <span className="courseBlock__info">Article 16</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">We&rsquo;ll look at the pros and cons of walking on either side.</span>
            </div>
          </li>
                
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/value-for-profit-position.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Value For Profit Position</span>
                <span className="courseBlock__info">Game 5</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Show your pup that good things happen when he&rsquo;s by your side.</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/value-for-profit-position-outside.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Value For Profit Position Outside</span>
                <span className="courseBlock__info">Game 6</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Test your pup&rsquo;s value for profit position in the real-world.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Putting the Profit in Profit Position</span>
                <span className="courseBlock__info">Article 17</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Building value for being at your side.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/profit-position-spins.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Profit Position Spins</span>
                <span className="courseBlock__info">Game 7</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Reward your pup for finding profit position, even as you rotate.</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/profit-position-black-hole.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Profit Position Black Hole</span>
                <span className="courseBlock__info">Game 8</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Teach your pup to find profit position from any angle and any distance.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Moving On From Position</span>
                <span className="courseBlock__info">Article 18</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">How do you know you&rsquo;re done with Position and ready for Moving?</span>
            </div>
          </li>
        </ul>
              

        <h3 className="chapterTitle">Stage 4: Moving</h3>
        <ul className="courseBlocks">
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Introduction to Moving</span>
                <span className="courseBlock__info">Article 19</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">What&rsquo;s this Moving section all about?</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/walk-together.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Walk Together</span>
                <span className="courseBlock__info">Game 9</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Build value for profit position while in motion.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/walk-together-outside.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Walk Together Outside</span>
                <span className="courseBlock__info">Game 10</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Practice your new informal heeling skill outside.</span>
            </div>
          </li>
                
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Take It Outside</span>
                <span className="courseBlock__info">Article 20</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Practicing in the real-world is the only way to improve.</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/profit-position-on-cue.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Profit Position on Cue</span>
                <span className="courseBlock__info">Game 11</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">When you need your pup to come in close, use the profit position cue.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/nice-and-easy-stops.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Nice &amp; Easy Stops</span>
                <span className="courseBlock__info">Game 12</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">A system for telling your pup you&rsquo;re about to stop.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Moving On From Moving</span>
                <span className="courseBlock__info">Article 21</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">How do you know you&rsquo;re done with Moving and ready for Distractions?</span>
            </div>
          </li>
        </ul>
              
        <h3 className="chapterTitle">Stage 5: Distractions</h3>
        <ul className="courseBlocks">
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Introduction to Distractions</span>
                <span className="courseBlock__info">Article 22</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">What&rsquo;s this Distractions section all about?</span>
            </div>
          </li>
                
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/leave-it.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Leave It</span>
                <span className="courseBlock__info">Game 13</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Build your pup&rsquo;s ability to ignore a distraction when asked.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/go-see.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Go See</span>
                <span className="courseBlock__info">Game 14</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">A communication tool for telling your pup it&rsquo;s okay to explore a distraction.</span>
            </div>
          </li>  

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Be Consistent With Your Dog&rsquo;s Permissions</span>
                <span className="courseBlock__info">Article 23</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Unlock your dog&rsquo;s best self with permission rituals.</span>
            </div>
          </li>
                
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/polite-approaches.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Polite Approaches</span>
                <span className="courseBlock__info">Game 15</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Eliminate overexcited pulling towards distractions.</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/polite-walk-bys.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Polite Walk-By&rsquo;s</span>
                <span className="courseBlock__info">Game 16</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Learn to calmly walk past distractions, even other dogs.</span>
            </div>
          </li>
              
          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Avoid Dogs Greeting Dogs on Walks</span>
                <span className="courseBlock__info">Article 24</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">An unfortunate lesson learned at Harper.</span>
            </div>
          </li>

              
          <li className="courseBlock courseBlock--game">
            <div className="courseBlock__top">
              <div className="courseBlock__cover">
                <img src="https://s3-us-east-2.amazonaws.com/media.harper.dog/speed-bumps.jpg" />
              </div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Speed Bumps</span>
                <span className="courseBlock__info">Game 17</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">Multiple techniques for slowing down a speedy leash puller.</span>
            </div>
          </li>  

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">Moving On from Distractions</span>
                <span className="courseBlock__info">Article 25</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">How do you know you&rsquo;re done with Distractions?</span>
            </div>
          </li>

          <li className="courseBlock courseBlock--article">
            <div className="courseBlock__top">
              <div className="courseBlock__cover"></div>
              <div className="courseBlock_top__wrap">
                <span className="courseBlock__name">What&rsquo;s Next After Walk Like a Champ?</span>
                <span className="courseBlock__info">Article 26</span>
              </div>
            </div>
            <div className="courseBlock__bottom">
              <span className="courseBlock__description prose">The course may be over but your work isn&rsquo;t. What should you do next?</span>
            </div>
          </li>
        </ul>
      </div>
      </div>
    </section>
  
    <section className="stripe">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">What&rsquo;s in every Harper subscription</h2>
          <ul className="benefitList">
            <li className="benefit">
              <span className="icon icon--courses"></span>
              <span className="benefit__name">All 30+ curated courses</span>
            </li>
            <li className="benefit">
              <span className="icon icon--article"></span>
              <span className="benefit__name">All 100+ training games and guides</span>
            </li>
            <li className="benefit">
              <span className="icon icon--video"></span>
              <span className="benefit__name">Step-by-step video instruction</span>
            </li>
            <li className="benefit">
              <span className="icon icon--chat"></span>
              <span className="benefit__name">Always-on dog trainer chat</span>
            </li>
            <li className="benefit">
              <span className="icon icon--reminders"></span>
              <span className="benefit__name">Reminders to develop a training habit</span>
            </li>
            <li className="benefit">
              <span className="icon icon--journal"></span>
              <span className="benefit__name">Training journal to track progress</span>
            </li>
            <li className="benefit">
              <span className="icon icon--squad"></span>
              <span className="benefit__name">Support from a dog parent community</span>
            </li>
          </ul>
        </div>
      </div>
    </section> 
                
    <section className="stripe">
      <div className="container">
        <div className="copy">
          <h2 className="sectionTitle">Frequently asked questions</h2>
          <h3 className="chapterTitle">General</h3>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>What is Harper?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p className="prose">Harper helps dog parents and their pups train, bond, and explore the world together. The app offers a library of over 30 guided training courses and almost 100 stimulating games, plus dog parenting resources, liver trainer chat, and community with other dog parents.</p>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>What&rsquo;s included in a Harper membership?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p className="prose">A Harper membership grants unlimited access to the entire library of 30+ courses, designed to help anyone achieve the relationship with their dog they dreamed of. Courses are made up of fun training games and short articles, expertly created and sequenced to help you master skills and conquer behavior issues at your own pace, from the comfort and safety of home.</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Which courses are right for me?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="prose">That all depends on your dog and your goals! Harper offers substantial multi-phase programs like Puppy Camp or The Every Dog Series. There are also courses targeted for specific skills you and your dog want to master. Check out the Discover tab to see all the available courses.</p>
                <p className="prose">You can also always ask us! We&rsquo;re happy to get on the phone to learn about you and recommend a plan.</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>Where can I use Harper?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="prose">Harper is an app for iOS. You can download and use it on any modern iPhone or iPad.</p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
          <h3 className="chapterTitle">Payment</h3>
          <Accordion allowZeroExpanded>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>How much does Harper cost?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p className="prose">A Harper membership costs $5.83 per month, billed annually as $69.99.</p>
              </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>How does the free trial work?</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                  <p className="prose">We want you and your pup to have the best time using Harper, so we offer free trials for all subscriptions to make sure it&rsquo;s the right fit for you.</p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>How do I cancel?</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p className="prose">If you need to no longer continue your membership, you can cancel your subscription through Apple on the Subscriptions page of the Settings app.</p>
                  <p className="prose"><a href="https://support.apple.com/en-us/HT202039" target="_blank" rel="noreferrer">This page</a> has instructions to follow. Once you cancel, you&rsquo;ll have access to Harper for the remainder of that billing period, then your subscription will not renew.</p>
                </AccordionItemPanel>
              </AccordionItem>
          </Accordion>
        </div>
      </div>
    </section>
  </Layout>
);

export default WalkingCoursePage
